import styled from 'styled-components'


export const BannerStyled = styled.section`
  background-color: #482573;
  .title,
  .description {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  .title {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  .description {
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  @media (max-width: 992px) {
    height: 325px;
    .title {
      font-size: 20px;
    }
    .description {
      font-size: 16px;
    }
  }

  @media (min-width: 992px) {
    height: 864px;
    .title {
      font-size: 80px;
    }
    .description {
      font-size: 28px;
    }
  }
`
