import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TitleSection from '../title-section'
import imgAbout from '../../assets/images/about/aboutUs.png?as=webp'
import { useTranslation } from 'react-i18next'
import { SectionStyled } from './styles'

const About = () => {
  const { t } = useTranslation()

  return (
      <SectionStyled id="about">
        <Container>
          <Row className="d-flex flex-wrap align-items-center">
            <Col md={6}>
              <div>
                <h1>
                  <TitleSection title={t('About.title')} align="left" />
                </h1>
                <p>{t('About.content.p1')}</p>
                <p>{t('About.content.p2')}</p>
                <p>{t('About.content.p3')}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="box">
                <img src={imgAbout} alt={t('About.title')} className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
  )
}

export default About
