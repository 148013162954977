import React, { useEffect, useState } from 'react'
import LanguageBtn from '../language-button/LanguageBtn'
import { Navbar, Container, Offcanvas, Nav } from 'react-bootstrap'
import { HeaderStyle } from './styles'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logoWhite from '../../assets/images/header/logo.png?as=webp'
import logoColor from '../../assets/images/header/logo-color.png?as=webp'
import enMenu from '../i18n/locales/enMenu.json'
import ptMenu from '../i18n/locales/ptMenu.json'
import '../i18n/i18n'
import i18next from 'i18next'

const Header = () => {
  const expand = 'xl'
  const [colorHeader, setColorHeader] = useState(false)
  const [colorHeaderLogo, setColorHeaderLogo] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language)
  const [viewWidth, setViewWidth] = useState({
    width: window.innerWidth,
    value: true,
  })

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const toggleMenu = () => {
    setIsMenuExpanded(prevState => !prevState);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language)
    i18next.changeLanguage(language)
  }

  let menu = selectedLanguage === 'pt-BR' ? ptMenu : enMenu

  const anchorClick = (name) => {
    menu.map((x) => {
      return {
        ...x,
        isActiveLink: x.name === name || false,
      }
    })
    menu.map((x) => {
      return {
        ...x,
        isActiveLink: x.name === name || false,
      }
    })
  }

  const changeColorHeaderScrollY = () => {
    if (window.scrollY >= 10) {
      setColorHeader(true)
      setColorHeaderLogo(true)
    } else {
      setColorHeader(false)
      setColorHeaderLogo(false)
    }
  }

  const handleResize = () => {
    const viewPort = window.innerWidth
    
    if (viewPort >= 992) {
      setViewWidth({ value: true })
    } else {
      setViewWidth({ value: false })
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize, false)
    handleResize()
    window.addEventListener('resize', handleResize, false)
    window.addEventListener('scroll', changeColorHeaderScrollY)
  }, [])


  return (
    <HeaderStyle className={colorHeader ? 'active' : ''}>
      <Navbar expand={expand}>
        <Container>
          <Navbar.Brand>
            <picture>
            <img
              src={colorHeaderLogo ? logoColor : logoWhite}
              alt="Logo"
              className="logo"
            />
            </picture>
          </Navbar.Brand>
          <Navbar.Toggle 
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          aria-label="toogle menu"
          aria-expanded={isMenuExpanded ? "true" : "false"}
          onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="18"
              viewBox="0 0 23 18"
            >
              <path
                id="bars"
                d="M22.692-16.969a.306.306,0,0,0,.308-.3v-1.213a.306.306,0,0,0-.308-.3H.308a.306.306,0,0,0-.308.3v1.213a.306.306,0,0,0,.308.3Zm0,8.09a.306.306,0,0,0,.308-.3V-10.4a.306.306,0,0,0-.308-.3H.308A.306.306,0,0,0,0-10.4v1.213a.306.306,0,0,0,.308.3Zm0,8.09a.306.306,0,0,0,.308-.3V-2.306a.306.306,0,0,0-.308-.3H.308a.306.306,0,0,0-.308.3v1.213a.306.306,0,0,0,.308.3Z"
                transform="translate(0 18.789)"
                fill="#fff"
              />
            </svg>
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            aria-label="toogle menu"
            show={isMenuExpanded} >
            <Offcanvas.Header closeButton onHide={toggleMenu} aria-label='close menu'/>
            <Offcanvas.Body>
              {!viewWidth.value ? (
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {menu.map((menu, index) => (
                    <Navbar.Toggle key={index}>
                      <AnchorLink
                        offset={() => 100}
                        href={menu.address}
                        name={menu.name}
                        onClick={() => {
                          anchorClick(menu.name)
                        }}
                        className={`link ${menu.isActiveLink ? 'active' : ''}`}
                      >
                        {menu.name}
                      </AnchorLink>
                    </Navbar.Toggle>
                  ))}
                  <div className={`row justify-content-center`}>
                    <LanguageBtn
                      classNameBtn={`languageBtn col-1`}
                      lngOption="en"
                      buttonText="EN"
                      onLanguageChange={handleLanguageChange}
                      selectedLanguage={selectedLanguage}
                    />
                    <span
                      className={`btnSeparation link col-1 justify-content-center`}
                    >
                      {/* <a className={`btnSeparation link`}>|</a> */}
                    </span>
                    <LanguageBtn
                      classNameBtn={`languageBtn col-1 justify-content-center p-0`}
                      lngOption="pt-BR"
                      buttonText="PT"
                      onLanguageChange={handleLanguageChange}
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                </Nav>
              ) : (
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {menu.map((menu, index) => (
                    <AnchorLink
                      checked={menu.isActiveLink}
                      key={index}
                      offset={() => 100}
                      href={menu.address}
                      name={menu.name}
                      onClick={() => {
                        anchorClick(menu.name)
                      }}
                      className={`link ${menu.isActiveLink ? 'active' : ''}`}
                    >
                      {menu.name}
                    </AnchorLink>
                  ))}
                  <div className={`row align-items-start`}>
                    <LanguageBtn
                      classNameBtn={`languageBtn col justify-content-start`}
                      lngOption="en"
                      buttonText="EN"
                      onLanguageChange={handleLanguageChange}
                      selectedLanguage={selectedLanguage}
                    />
                    <span className={`btnSeparation link col m-0 p-0`}>
                      {/* <a className={`btnSeparation link`}>|</a> */}
                    </span>
                    <LanguageBtn
                      classNameBtn={`languageBtn col justify-content-start`}
                      lngOption="pt-BR"
                      buttonText="PT"
                      onLanguageChange={handleLanguageChange}
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                </Nav>
              )}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </HeaderStyle>
  )
}

export default Header
