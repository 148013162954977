import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import i18n from './components/i18n/i18n.js'
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <I18nextProvider i18n={ i18n }>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </I18nextProvider>
)
