import styled from "styled-components";

export const SectionStyled = styled.section`
  background-color: ${({ theme }) => theme.palette.background.primary};
  background-size: cover;
  padding: 30px 0px;

  a{
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-bottom: 10px;
    svg{
      margin-right: 9px;
    }
  }
  @media (max-width: 992px) {
    .logo {
      width: 159px;
      margin: auto;
      text-align: center;
      display: flex;
      margin-bottom: 26px;
    }
  }
  
`