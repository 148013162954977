import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  .img-fluid{
    max-width: 101%;
  }

  @media(max-width:992px){
    .offcanvas{
      .offcanvas-header{
        justify-content: end;
        .btn-close{
          color: ${({ theme }) => theme.palette.primary.dark};
          font-size: 18px;
        }
      }
      .link{
        color: ${({ theme }) => theme.palette.primary.dark};
        font-size: 16px;
        text-decoration: none;
        margin-bottom: 15px;
        display: block;
        font-weight:600;
      }
      .bold{
        font-weight:800;
      }
    }
  }
`
