export const differentialList = [
  {
    title: 'Agile methodologies',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="35.047" height="35.046" viewBox="0 0 35.047 35.046">
        <path id="agile" d="M30.146.785a3.181,3.181,0,0,1,3.486.171,3.055,3.055,0,0,1,1.436,3.179l-4.1,26.523a3.132,3.132,0,0,1-1.641,2.324,3.239,3.239,0,0,1-1.572.41,4.08,4.08,0,0,1-1.3-.205L18.8,30.043l-2.939,4.033a3.4,3.4,0,0,1-2.358,1.3,3.18,3.18,0,0,1-2.5-.82,3.121,3.121,0,0,1-1.094-2.461V26.352L2.119,23.07a3.015,3.015,0,0,1-2.051-2.8,3.07,3.07,0,0,1,1.641-3.076ZM13.193,32.094l2.529-3.418L13.193,27.65Zm14.561-1.982L31.787,3.656,3.35,20.063l7.383,3.076L25.156,10.492a1.012,1.012,0,0,1,1.47.034A1.017,1.017,0,0,1,26.8,12L17.227,25.8Z" transform="translate(-0.059 -0.346)" fill="#fff" />
      </svg>
  },
  {
    title: 'Incremental deliveries',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="43.753" height="34.997" viewBox="0 0 43.753 34.997">
        <path id="tag" d="M19.065,35.341a.819.819,0,0,0,1.019-.561L29.415,2.638a.822.822,0,0,0-.561-1.019L24.685.409A.822.822,0,0,0,23.666.97L14.335,33.112a.821.821,0,0,0,.561,1.019Zm-7.793-7.67L14.246,24.5a.82.82,0,0,0-.055-1.176L8,17.875l6.193-5.448a.815.815,0,0,0,.055-1.176L11.272,8.079a.821.821,0,0,0-1.162-.034L.26,17.273a.815.815,0,0,0,0,1.2l9.851,9.235A.815.815,0,0,0,11.272,27.671Zm22.367.041,9.851-9.235a.815.815,0,0,0,0-1.2L33.64,8.038a.828.828,0,0,0-1.162.034L29.5,11.244a.82.82,0,0,0,.055,1.176l6.193,5.455-6.193,5.448A.815.815,0,0,0,29.5,24.5l2.974,3.172A.821.821,0,0,0,33.64,27.712Z" transform="translate(0.002 -0.378)" fill="#fff" />
      </svg>
  },
  {
    title: '100% home office employees',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="39.378" height="30.622" viewBox="0 0 39.378 30.622">
        <path id="home" d="M19.166,10.51,6.563,20.89v11.2a1.094,1.094,0,0,0,1.094,1.094l7.66-.02A1.094,1.094,0,0,0,16.4,32.074V25.531A1.094,1.094,0,0,1,17.5,24.438h4.375a1.094,1.094,0,0,1,1.094,1.094v6.538s0,0,0,0a1.094,1.094,0,0,0,1.094,1.094l7.658.021a1.094,1.094,0,0,0,1.094-1.094V20.883L20.212,10.51a.834.834,0,0,0-1.046,0Zm19.908,7.055-5.715-4.711V3.386a.821.821,0,0,0-.82-.82H28.711a.821.821,0,0,0-.82.82V8.35L21.77,3.314a3.285,3.285,0,0,0-4.17,0L.3,17.565a.819.819,0,0,0-.109,1.155L1.93,20.84a.819.819,0,0,0,1.156.111L19.166,7.707a.834.834,0,0,1,1.046,0l16.08,13.244a.821.821,0,0,0,1.155-.109l1.743-2.119a.821.821,0,0,0-.116-1.157Z" transform="translate(0.002 -2.566)" fill="#fff" />
      </svg>
  },
  {
    title: 'High efficiency',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <path id="Caminho_2344" data-name="Caminho 2344" d="M34.53,1.68A1.181,1.181,0,0,0,33.7.847a24.8,24.8,0,0,0-5.64-.472C21,.375,16.766,4.149,13.607,9.125H6.483a3.6,3.6,0,0,0-2.932,1.81L.172,17.69A1.945,1.945,0,0,0,0,18.422a1.642,1.642,0,0,0,1.641,1.641h7.1L7.2,21.6a2.226,2.226,0,0,0,0,3.093l3.48,3.48a2.229,2.229,0,0,0,3.1,0l1.536-1.536v7.1a1.642,1.642,0,0,0,1.641,1.641,1.956,1.956,0,0,0,.732-.172l6.749-3.376a3.583,3.583,0,0,0,1.812-2.93V21.758C31.209,18.592,35,14.347,35,7.327A24.263,24.263,0,0,0,34.53,1.68ZM26.253,11.859a2.734,2.734,0,1,1,2.735-2.734A2.736,2.736,0,0,1,26.253,11.859Z" transform="translate(0 -0.375)" fill="#fff" />
      </svg>
  },
  {
    title: 'Continuous communication with the customer',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="39.409" height="30.625" viewBox="0 0 39.409 30.625">
        <path id="communication_" data-name="communication " d="M36.436,26.762a14.659,14.659,0,0,0,1.128,1.88q.649.923,1.025,1.367t.444.513a1.485,1.485,0,0,1,.273,1.709,1.451,1.451,0,0,1-1.436.957,15.457,15.457,0,0,1-8.545-2.666,18,18,0,0,1-4.1.479,16.743,16.743,0,0,1-7.9-1.846A12.007,12.007,0,0,1,12.168,24.3q-1.025-.137-1.982-.342a15.237,15.237,0,0,1-8.545,2.666,1.451,1.451,0,0,1-1.436-.957,1.485,1.485,0,0,1,.273-1.709,12.955,12.955,0,0,0,2.6-3.76A9.131,9.131,0,0,1,.068,13.5q0-4.512,4.17-7.725A15.975,15.975,0,0,1,14.287,2.563a16.948,16.948,0,0,1,7.93,1.846,11.973,11.973,0,0,1,5.2,4.854,15.4,15.4,0,0,1,8.579,3.657,9.255,9.255,0,0,1,3.452,7.144A9.131,9.131,0,0,1,36.436,26.762ZM9.57,20.473l1.367.273a13.758,13.758,0,0,0,3.35.41,13.139,13.139,0,0,0,7.69-2.256q3.247-2.256,3.247-5.4T21.978,8.1a13.139,13.139,0,0,0-7.69-2.256A13.139,13.139,0,0,0,6.6,8.1Q3.35,10.355,3.35,13.5A5.987,5.987,0,0,0,5.4,17.875l1.641,1.641L6.016,21.7l-.547.957a11.684,11.684,0,0,0,2.939-1.436Zm24.541,3.965a5.987,5.987,0,0,0,2.051-4.375,6,6,0,0,0-2.187-4.478,11.8,11.8,0,0,0-5.537-2.769q.068.547.068.684A8.872,8.872,0,0,1,26.9,18.593a12.174,12.174,0,0,1-4.409,3.862A16.485,16.485,0,0,1,16.27,24.3a11.968,11.968,0,0,0,8.955,3.418,13.758,13.758,0,0,0,3.35-.41l1.367-.273,1.162.752a11.684,11.684,0,0,0,2.939,1.436l-.547-.957-1.025-2.187Z" transform="translate(-0.051 -2.563)" fill="#fff" />
      </svg>
  },

]