import styled from "styled-components";

export const SectionStyled = styled.section`
  padding: 100px 0px;
  p{
    color: ${({ theme }) => theme.palette.text.text};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 25px;
  }
  @media (max-width: 992px){
    padding: 40px 0px;
  }
`