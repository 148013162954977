import React from 'react'
import emailjs from '@emailjs/browser'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Col, Container, Row, Form, FloatingLabel } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { useTranslation } from 'react-i18next'
import TitleSection from '../title-section'
import { ContainerStyled, FormStyled, ButtonStyled } from './styles'
import LinkButton from '../calendlyBtn'

const Contact = () => {
  const { t } = useTranslation()
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async (data) => {
    try {
      const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID
      const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
      const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

      const response = await emailjs.send(
        serviceId,
        templateId,
        data,
        publicKey
      )

      if (response.status === 200) {
        toast.success(t('Toast.success'), {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

        reset()
      } else {
        toast.error(t('Toast.error'), {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } catch (error) {
      toast.error(t('Toast.error'), {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const svgError = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.039"
      height="14"
      viewBox="0 0 16.039 14"
    >
      <path
        id="Caminho_2342"
        data-name="Caminho 2342"
        d="M15.844,13.031a1.181,1.181,0,0,1,.016,1.313A1.277,1.277,0,0,1,14.688,15H1.375A1.277,1.277,0,0,1,.2,14.344a1.181,1.181,0,0,1,.016-1.312L6.875,1.656A1.242,1.242,0,0,1,8.031,1a1.242,1.242,0,0,1,1.156.656ZM7.281,5.25v4a.75.75,0,1,0,1.5,0v-4a.75.75,0,1,0-1.5,0Zm.063,7.469A.969.969,0,0,0,9,12.031a.988.988,0,0,0-.281-.7.944.944,0,0,0-1.375,0,.988.988,0,0,0-.281.7A.935.935,0,0,0,7.344,12.719Z"
        transform="translate(-0.012 -1)"
        fill="#f05357"
      />
    </svg>
  )

  return (
    <>
      <ContainerStyled id="contact">
        <Container>
          <Row>
            <Col md={12}>
              <LinkButton/>
              <h4>
                <TitleSection
                  title={t('Contact us.title')}
                  description={t('Contact us.content')}
                  align="center"
                />
              </h4>
            </Col>
            <Col md={{ span: 6, offset: 3 }}>
              <FormStyled>
                <ToastContainer />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FloatingLabel
                    controlId="contact_name"
                    label={t('Contact Form.labelName')}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder={t('Contact Form.labelName')}
                      {...register('contact_name', {
                        required: t('Contact Form.requiredNameMsg'),
                      })}
                    />
                    <ErrorMessage
                      id="errorFullName"
                      errors={errors}
                      name="contact_name"
                      render={({ message }) => (
                        <p className="error d-flex align-items-center">
                          {svgError}
                          {message}
                        </p>
                      )}
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="contact_mail"
                    label="E-mail"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="E-mail"
                      {...register('contact_mail', {
                        required: t('Contact Form.requiredEmailMsg'),
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="contact_mail"
                      render={({ message }) => (
                        <p className="error d-flex align-items-center">
                          {svgError}
                          {message}
                        </p>
                      )}
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="contact_phone"
                    label={t('Contact Form.Phone')}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder={t('Contact Form.Phone')}
                      {...register('contact_phone', {
                        required: t('Contact Form.requiredPhoneMsg'),
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="contact_phone"
                      render={({ message }) => (
                        <p className="error d-flex align-items-center">
                          {svgError}
                          {message}
                        </p>
                      )}
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="contact_address"
                    label={t('Contact Form.labelAdress')}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder={t('Contact Form.labelAdress')}
                      {...register('contact_address', {
                        required: t('Contact Form.requiredAddressMsg'),
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="contact_address"
                      render={({ message }) => (
                        <p className="error d-flex align-items-center">
                          {svgError}
                          {message}
                        </p>
                      )}
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="message"
                    label={t('Contact Form.yourMessage')}
                  >
                    <Form.Control
                      as="textarea"
                      placeholder={t('Contact Form.yourMessage')}
                      style={{ height: '140px' }}
                      {...register('message', {
                        required: t('Contact Form.requiredMsg'),
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="message"
                      render={({ message }) => (
                        <p className="error d-flex align-items-center">
                          {svgError}
                          {message}
                        </p>
                      )}
                    />
                  </FloatingLabel>

                  <ButtonStyled variant="primary" type="submit">
                    {t('Contact Form.sendButton')}
                  </ButtonStyled>
                  {/* <div className="mb-3 d-flex justify-content-center">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      label="Li e estou ciente da política de privacidade."
                    />
                  </div> */}
                </Form>
              </FormStyled>
            </Col>
          </Row>
        </Container>
      </ContainerStyled>
    </>
  )
}

export default Contact
