import styled from 'styled-components'
import { PopupButton } from 'react-calendly';
import { Container } from "react-bootstrap";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-bottom: 5.5rem;
`;

export const StyledPopupButton = styled(PopupButton)`
  background-color: #482573 !important;
  padding-left: 10rem !important;
  padding-right: 10rem !important;
  
  @media(max-width: 992px) {
    padding-left: 4rem !important;
    padding-right: 4rem !important; 
    font-size: 15px !important;
  }
`;