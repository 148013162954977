import styled from 'styled-components'

export const TitleStyled = styled.div`
  .title {
    color: ${({ theme }) => theme.palette.text.title};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    position: relative;
    svg{
      margin-right: 5px;
      vertical-align: inherit;
    }
  }
  .description {
    color: ${({ theme }) => theme.palette.text.description};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  @media (max-width: 992px) {
    .title {
      font-size: 28px;
      svg{
        height: 20px;
      }
    }
    .description {
      font-size: 16px;
    }
  }

  @media (min-width: 992px) {
    .title {
      font-size: 40px;
    }
    .description {
      font-size: 20px;
    }
  }
`
