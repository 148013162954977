import styled from "styled-components";
export const ContainerStyled = styled.section`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: 100px 0px;
  @media (max-width: 992px) {
    padding: 60px 0px;
  }

`
export const FormStyled = styled.section`
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 5%);
  labe{
    color: #62635E;
  }
  .form-control{
    background-color: #F2F2F2;
    border-radius: 8px;
    border: 0px;
    color: #62635E;
    font-size: 14px;
    &:placeholder{
      color: #62635E;
    }
  }
  .error{
    color: ${({ theme }) => theme.palette.error.light};
    position: relative;
    font-size: 13px;
    padding: 6px;
    svg{
      margin-right: 5px;
    }
  }
  .form-check-input{
    &:checked {
      background-color: #482573;
      border-color: #482573;
    }
  }
  @media (max-width: 992px) {
    padding: 25px;
  }
`
export const ButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.palette.background.primary};
  color:  ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  padding: 10px 12px;
  border: 0px;
  border-radius: 50px;
  width: 83%;
  margin: 20px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  &:hover{
    background-color: ${({ theme }) => theme.palette.background.primaryMain};
  }
`