import Router from './router'
import Theme from './UI/theme'
import { GlobalStyle } from './UI/global-style'
import 'bootstrap/dist/css/bootstrap.min.css'
import i18n from './components/i18n/i18n.js'
import { I18nextProvider } from 'react-i18next';
import React from 'react'


export default function App() {
  
  return (
    <Theme>
      <I18nextProvider i18n={i18n}>
      <GlobalStyle />
      <Router />
      </I18nextProvider>
    </Theme>
  )
}



