import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TitleSection from '../title-section'
import { servicesList } from '../../mocks/services'
import { useTranslation } from 'react-i18next'
import { SectionStyled } from './styles'

const Services = () => {

  const { t } = useTranslation()

  return (
      <SectionStyled id="services">
        <Container>
          <Row className="d-flex flex-wrap align-items-center">
            <Col md={12}>
              <div className="box">
                <h2 className="title-service">
                  <TitleSection
                    title={t('Our Services.title')}
                    align="center"
                  />
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            {servicesList.map((servicesList, index) => (
              <Col md={4} key={index}>
                <div className="box">
                  <div className="item text-center">
                    <div className="icone">{servicesList.icon}</div>
                    <div className="text">
                      <p className="title">
                        {t(servicesList.title + '.title')}
                      </p>
                      <p className="description">
                        {t(servicesList.title + '.content')}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </SectionStyled>
  )
}

export default Services
