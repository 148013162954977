export const servicesList = [
  {
    title: 'Development',
    description: 'Development of custom projects tailored to your company',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="40.001"
        viewBox="0 0 45 40.001"
      >
        <path
          id="tagHtml"
          d="M18.344,39.977a.622.622,0,0,0,.773-.43l11-37.867a.631.631,0,0,0-.43-.773L26.656.023a.631.631,0,0,0-.773.43l-11,37.867a.631.631,0,0,0,.43.773Zm-6.5-9.461,2.125-2.266a.632.632,0,0,0-.039-.9L5.641,20l8.289-7.352a.626.626,0,0,0,.039-.9L11.844,9.484a.628.628,0,0,0-.883-.031L.2,19.547a.624.624,0,0,0,0,.914L10.961,30.547A.628.628,0,0,0,11.844,30.516Zm22.2.031L44.8,20.461a.624.624,0,0,0,0-.914L34.039,9.453a.62.62,0,0,0-.883.031L31.031,11.75a.632.632,0,0,0,.039.9L39.359,20,31.07,27.352a.626.626,0,0,0-.039.9l2.125,2.266A.628.628,0,0,0,34.039,30.547Z"
          transform="translate(0.004 0)"
          fill="#7b58a6"
        />
      </svg>
    ),
  },
  {
    title: 'Enterprises',
    description: 'Web and mobile software development services',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="40"
        viewBox="0 0 50 40"
      >
        <path
          id="notebook"
          d="M48.75,27.5H45V5a5.015,5.015,0,0,0-5-5H10A5.015,5.015,0,0,0,5,5V27.5H1.25A1.254,1.254,0,0,0,0,28.75V32.5A7.522,7.522,0,0,0,7.5,40h35A7.522,7.522,0,0,0,50,32.5V28.75A1.254,1.254,0,0,0,48.75,27.5ZM8.75,5A1.267,1.267,0,0,1,10,3.75H40A1.267,1.267,0,0,1,41.25,5V27.5H8.75Zm37.5,27.5a3.754,3.754,0,0,1-3.75,3.75H7.5A3.754,3.754,0,0,1,3.75,32.5V31.25H17.883A2.464,2.464,0,0,0,20,32.5H30a2.465,2.465,0,0,0,2.117-1.25H46.25Z"
          fill="#7b58a6"
        />
      </svg>
    ),
  },
  {
    title: 'Outsourcing',
    description: 'Software engineering partner for your company',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="30"
        viewBox="0 0 50 30"
      >
        <path
          id="hands"
          d="M40.563,9.992,36.844,6.273A4.382,4.382,0,0,0,33.75,5H16.031a4.409,4.409,0,0,0-3.094,1.273L9.219,9.992H0V29.969H5a2.491,2.491,0,0,0,2.492-2.477H8.2l6.609,5.969a6.875,6.875,0,0,0,8.25.3,4.795,4.795,0,0,0,7.023-.633,4.383,4.383,0,0,0,5-1.312l2.047-2.523a4.285,4.285,0,0,0,.852-1.8h4.523A2.5,2.5,0,0,0,45,29.969h5V9.992ZM3.75,27.469A1.25,1.25,0,1,1,5,26.219,1.254,1.254,0,0,1,3.75,27.469Zm30.469-.539L32.18,29.445a.63.63,0,0,1-.883.094L29.43,28.023l-2.344,2.852a1.045,1.045,0,0,1-1.406.187L22.8,28.6l-1.219,1.5a3.113,3.113,0,0,1-4.32.516l-7.6-6.875H7.5V13.734h3.273l4.82-4.812.445-.18h4.43l-3.023,2.773A5.592,5.592,0,0,0,17.1,19.43a5.66,5.66,0,0,0,7.93.344l.641-.586,8.453,6.859A.638.638,0,0,1,34.219,26.93ZM42.5,23.742H37.094a4.369,4.369,0,0,0-.6-.6l-8.023-6.516.977-.891a1.249,1.249,0,0,0,.078-1.766l-.852-.914a1.256,1.256,0,0,0-1.766-.078L22.594,16.93a1.987,1.987,0,0,1-2.7,0,1.854,1.854,0,0,1,.094-2.648l5.125-4.7a3.109,3.109,0,0,1,2.109-.82l6.539-.016a.584.584,0,0,1,.43.18l4.82,4.813H42.5Zm3.75,3.727a1.25,1.25,0,1,1,1.25-1.25A1.254,1.254,0,0,1,46.25,27.469Z"
          transform="translate(0 -5)"
          fill="#7b58a6"
        />
      </svg>
    ),
  },
  {
    title: 'UI/UX Design',
    description: ' Mobile and web design to delight and engage users',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <path
          id="paint"
          d="M38.584,7.469,32.531,1.416a4.834,4.834,0,0,0-6.838,0L18.471,8.639l-7.8,2.2a5.014,5.014,0,0,0-3.384,3.23L0,35.938,4.063,40l21.864-7.288a5.014,5.014,0,0,0,3.23-3.384l2.2-7.8,7.223-7.223A4.835,4.835,0,0,0,38.584,7.469ZM25.548,28.309a1.25,1.25,0,0,1-.808.846L6.549,35.218l8.093-8.093a3.785,3.785,0,1,0-1.767-1.767L4.782,33.451l6.064-18.191a1.25,1.25,0,0,1,.846-.808l7.8-2.2.082-.023,8.2,8.2-.023.081ZM35.932,11.655l-5.813,5.813L22.532,9.881l5.813-5.813a1.1,1.1,0,0,1,1.534,0l6.052,6.052A1.085,1.085,0,0,1,35.932,11.655Z"
          fill="#7b58a6"
        />
      </svg>
    ),
  },
  {
    title: 'Consultancy',
    description: 'Grow your projects with experienced developer consultancy',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 40 35"
      >
        <path
          id="bag"
          d="M36.25,10H30V6.25A3.75,3.75,0,0,0,26.25,2.5H13.75A3.75,3.75,0,0,0,10,6.25V10H3.75A3.75,3.75,0,0,0,0,13.75v20A3.75,3.75,0,0,0,3.75,37.5h32.5A3.75,3.75,0,0,0,40,33.75v-20A3.75,3.75,0,0,0,36.25,10ZM13.75,6.25h12.5V10H13.75Zm-9.531,7.5H35.781a.469.469,0,0,1,.469.469V20H3.75V14.219A.469.469,0,0,1,4.219,13.75Zm31.563,20H4.219a.469.469,0,0,1-.469-.469V23.75H15v1.875A1.875,1.875,0,0,0,16.875,27.5h6.25A1.875,1.875,0,0,0,25,25.625V23.75H36.25v9.531A.469.469,0,0,1,35.781,33.75Z"
          transform="translate(0 -2.5)"
          fill="#7b58a6"
        />
      </svg>
    ),
  },
]
