import React from 'react'
import Layout from '../../components/layout'
import Banner from '../../components/banner'
import About from '../../components/about'
import Contact from '../../components/contact'
import Services from '../../components/services'
import Differential from '../../components/differential'

const Home = () => {
  return (
    
      <Layout>
        <Banner />
        <About />
        <Services />
        <Differential />
        <Contact />
      </Layout>
    
  )
}

export default Home
