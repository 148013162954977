import styled from "styled-components";

export const SectionStyled = styled.section`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: 100px 0px;
  .title-service{
    margin-bottom: 80px
  }
  .item{
    max-width: 240px;
    margin: 30px auto 30px auto;
    .icone{
      margin-bottom: 15px ;
    }
    .title{
      font-size: 20px;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.palette.text.title};
      margin-bottom: 0px;
    }
    .description{
      font-size: 16px;
      line-height: 28px;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      color: ${({ theme }) => theme.palette.text.text};
    }
  }

  @media (max-width: 992px) {
    padding: 60px 0px;
    .title-service{
      margin-bottom: 30px
    }
    .item{
      margin-top: 15px;
    }
  }

`