import '../i18n/i18n'

const LanguageBtn = ({
  classNameBtn,
  lngOption,
  buttonText,
  selectedLanguage,
  onLanguageChange,
}) => {
  const handleLanguageChange = () => {
    onLanguageChange(lngOption)
  }

  const classes = `${classNameBtn} link ${
    selectedLanguage === lngOption ? ' bold' : ''
  }`

  return (
    // eslint-disable-next-line
    <a className={classes} onClick={handleLanguageChange} value={lngOption} aria-label={"Change site language to "+ lngOption}  tabIndex='0' role='link'>
      {buttonText}
    </a>
  )
}

export default LanguageBtn
