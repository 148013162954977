import React from 'react'
import { createGlobalStyle, ThemeProvider, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    ${css`
      font-family:'Open Sans','Nunito' !important; 
    `}
  }
`;

const theme = {
  palette: {
    primary: {
      light: '#7b58a6',
      main: '#5B2F90',
      dark: '#482573',
      contrastText: '#ffffff',
    },
    error: {
      light: '#f05357',
      main: '#ED282E',
      dark: '#a51c20',
      contrastText: '#fff',
    },
    text: {
      title: '#2C2C2D',
      description: '#707070',
      text: '#6F7581',
    },
    background: {
      primary: '#482573',
      secondary: '#FCFCFC',
      primaryMain: '#5B2F90',
    },
  },
  fonts: ['Open Sans','Nunito','Roboto'],
  fontWeight: {
    extraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    extraBold: '800',
    black: '900',
  },
}

const Theme = ({ children }) => (
  <>
  <GlobalStyle/>
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)

export default Theme
