import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BannerStyled } from './styles'

const Banner = () => {
  const {t} = useTranslation()
  const titleBanner = t('mainTitle.title')
  const descriptionBanner = t('mainTitle.content')

  return (
      <BannerStyled id="home">
        <Container className="h-100">
          <Row className="h-100">
            <Col className="h-100 d-flex flex-wrap flex-column align-items-center justify-content-center">
              <div className="title">
                      {titleBanner}
              </div>
              <div className='description'>
                     {descriptionBanner}
              </div>
            </Col>
          </Row>
        </Container>
      </BannerStyled>
  )
}

export default Banner
