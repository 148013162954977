import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TitleSection from '../title-section'
import { differentialList } from '../../mocks/differential'
import { useTranslation } from 'react-i18next'
import { SectionStyled } from './styles'

const Differential = () => {

  const { t } = useTranslation()

  return (
      <SectionStyled id="differential">
        <Container>
          <Row className="d-flex flex-wrap align-items-center">
            <Col md={12}>
              <div className="box">
                <h3 className="title-differential">
                  <TitleSection
                    title={t('Differentials.title')}
                    align="center"
                  />
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9, offset: 2 }}>
              <Row>
                {differentialList.map((differentialList, index) => (
                  <Col md={6} key={index}>
                    <div className="box">
                      <div className="item d-flex align-items-center">
                        <div className="icone">{differentialList.icon}</div>
                        <p className="title">
                          {' '}
                          {t(differentialList.title + '.title')}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
  )
}

export default Differential
