import styled from 'styled-components'

export const HeaderStyle = styled.header`
  position: fixed;
  top: 0px;
  width: 100%;
  transition: all ease-in 0.4s;
  z-index: 99;
  font-weight: 600;

  &.active {
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 5%);
    svg {
      path {
        fill: ${({ theme }) => theme.palette.primary.dark};
      }
    }
    a.link {
      color: #2c2c2d;
      border: none;
      &.active {
        color: ${({ theme }) => theme.palette.primary.dark};
        text-decoration: underline;
      }
    }
  }

  .navbar-toggler {
    border: 0px;
    &:focus {
      box-shadow: none;
    }
  }

  .bold {
    font-weight: 800 !important;
  }

  a.link {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-size: 16px;
    text-decoration: none;
    margin-left: 45px;
    cursor:pointer;
    }

  a.link:last-child {
    margin-left: 0;
    border-width: 0;
    border: none;
    margin: 0;
  }

  .btnSeparation {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 992px) {
    .logo {
      height: 62px;
      width: 62px;
    }
  }

  @media (min-width: 992px) {
    &.active {
      .logo {
        height: 80px;
        width: 80px;
      }
    }
    .logo {
      height: 100px;
      width: 100px;
    }
  }
`
