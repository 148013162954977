import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logoWhite from '../../assets/images/footer/logo.png?as=webp'



import { SectionStyled } from './styles'

const Footer = () => {
  return (
    <SectionStyled>
      <Container>
        <Row className="d-flex align-items-center">
          <Col md={4}>
            <picture>
            <img src={logoWhite} alt="Logo" className="logo img-fluid" />
            </picture>
          </Col>
          <Col md={4}>
            <a href="tel:555436988946" className="telephone" aria-label='Phone'>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.002"
                height="24.994"
                viewBox="0 0 25.002 24.994"
              >
                <path
                  id="Caminho_2345"
                  data-name="Caminho 2345"
                  d="M23.267,1.243l-4.6-1.06a2.228,2.228,0,0,0-2.554,1.3L13.989,6.438a2.24,2.24,0,0,0,.64,2.607l1.953,1.6a15.184,15.184,0,0,1-6.064,6.06l-1.6-1.953a2.24,2.24,0,0,0-2.607-.64L1.353,16.233a2.233,2.233,0,0,0-1.3,2.554l1.06,4.6A2.226,2.226,0,0,0,3.291,25.12,21.7,21.7,0,0,0,25,3.421,2.226,2.226,0,0,0,23.267,1.243ZM3.384,22.781l-1.02-4.429L7.158,16.3l2.72,3.325A17.7,17.7,0,0,0,19.5,10l-3.325-2.72,2.056-4.795,4.429,1.02A19.36,19.36,0,0,1,3.384,22.781Z"
                  transform="translate(0.002 -0.126)"
                  fill="#c6a8e9"
                />
              </svg>
              <span>+55 (54) 3698-8946</span>
            </a>
          </Col>
          <Col md={4}>
            <a href="tel:5554981491193" className="email">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="18.75"
                viewBox="0 0 25 18.75"
              >
                <path
                  id="Caminho_2346"
                  data-name="Caminho 2346"
                  d="M21.875,3.25A3.085,3.085,0,0,1,25,6.375v12.5A3.085,3.085,0,0,1,21.875,22H3.125A3.02,3.02,0,0,1,.9,21.1,3.02,3.02,0,0,1,0,18.875V6.375A3.02,3.02,0,0,1,.9,4.153a3.02,3.02,0,0,1,2.222-.9ZM3.125,5.594a.752.752,0,0,0-.781.781V7.449L10.5,14.236a2.922,2.922,0,0,0,2,.732,2.922,2.922,0,0,0,2-.732l8.154-6.787V6.375a.752.752,0,0,0-.781-.781Zm18.75,14.063a.752.752,0,0,0,.781-.781v-8.35l-6.641,5.518a5.307,5.307,0,0,1-3.516,1.27,5.307,5.307,0,0,1-3.516-1.27L2.344,10.525v8.35a.752.752,0,0,0,.781.781Z"
                  transform="translate(0 -3.25)"
                  fill="#c6a8e9"
                />
              </svg>

              <span>contact@confidencedevs.com</span>
            </a>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  )
}
export default Footer
