import { useTranslation } from "react-i18next";
import { StyledPopupButton, StyledContainer } from "./styles";

const LinkButton = () => { 
  const { t } = useTranslation()

  return (  
    <StyledContainer className="btn__Container">
      <StyledPopupButton
        aria-label={t('Button.schedule')}
        className="btn btn-primary background btn-lg btn-block rounded-pill"
        url='https://calendly.com/confidencedevs/intro?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=482573'
        rootElement={document.getElementById("root")}
        text={t('Button.schedule')}
      />
    </StyledContainer>
  );
};

export default LinkButton;

