import React from 'react'
import { TitleStyled } from './styles'

const TitleSection = ({ title, description, align }) => {
  return (
    <TitleStyled className={`text-${align}`}>
      <p className="title">
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="34" viewBox="0 0 5 34">
          <path id="Caminho_2343" data-name="Caminho 2343" d="M0,0H5V34H0Z" fill="#482573" />
        </svg>
        {title}</p>
      {description ? (
        <p className="description">{description}</p>
      ) : null}
    </TitleStyled>
  )
}

export default TitleSection
