import styled from 'styled-components'
import bgImg from '../../assets/images/differential/bg.jpg'

export const SectionStyled = styled.section`
  background: url(${bgImg}) no-repeat center bottom;
  background-size: cover;
  padding: 180px 0px;
  .title-differential {
    margin-bottom: 80px;
    svg {
      display: none;
    }
    .title,
    .description {
      color: #fff;
    }
  }
  .item {
    margin-bottom: 35px;
    .icone {
      margin-right: 15px;
    }
    .title {
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeight.light};
      color: ${({ theme }) => theme.palette.primary.contrastText};
      margin-bottom: 0px;
    }
  }

  @media (max-width: 992px) {
    padding: 60px 0px;
    .title-differential {
      margin-bottom: 30px;
    }
    .item {
      margin-top: 15px;
    }
  }
`
