import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ptbr from './locales/ptbr.json';
import en from './locales/en.json';
import { initReactI18next } from 'react-i18next';


const resources = {
  'pt-BR': {
    'translation': ptbr
  },
  'en': {
    'translation': en
  } 
};
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
  resources,
  fallbackLng:'en',
  debug: true,
  react: {
  useSuspense:true,
  withRef: false,
  bindI18n: 'languageChanged loaded',
  bindStore: 'added removed',
  nsMode: 'default'
}
})

 
export default i18n;

